// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  debug: true,
  url: "https://tv5ak0bsle.execute-api.eu-north-1.amazonaws.com/dev/api/v1",
  autologout: true,
  timeout: 15,
  cropRatio: "9:16",
  cropRatios: ["16:9", "9:16", "3:4", "4:3", "1:1", "free"],
  bynderImageCDN: "https://securema2.dev.ikeadt.com/m",
  akamaiImageCDN: "https://securema2.dev.ikeadt.com/m",
  ladanUrl: "https://run-api-fmcw67neya-ew.a.run.app",
  adalConfig: {
    production: true,
    expireOffsetSeconds: 900,
    tenant: "720b637a-655a-40cf-816a-f22f40755c2c",
    clientId: "684f854f-444b-4cd0-9b79-dad07bb12f57",
    redirectUri: window.location.origin,
    endpoints: {
      "https://tv5ak0bsle.execute-api.eu-north-1.amazonaws.com/dev/api/v1/collections":
        "684f854f-444b-4cd0-9b79-dad07bb12f57",
      "https://tv5ak0bsle.execute-api.eu-north-1.amazonaws.com/dev/api/v1/media":
        "684f854f-444b-4cd0-9b79-dad07bb12f57",
      "https://tv5ak0bsle.execute-api.eu-north-1.amazonaws.com/dev/api/v1/collection":
        "684f854f-444b-4cd0-9b79-dad07bb12f57",
      "https://tv5ak0bsle.execute-api.eu-north-1.amazonaws.com/dev/api/v1/search":
        "684f854f-444b-4cd0-9b79-dad07bb12f57",
      "https://tv5ak0bsle.execute-api.eu-north-1.amazonaws.com/dev/api/v1/content":
        "684f854f-444b-4cd0-9b79-dad07bb12f57",
      "https://tv5ak0bsle.execute-api.eu-north-1.amazonaws.com/dev/api/v1/asset":
        "684f854f-444b-4cd0-9b79-dad07bb12f57",
      "https://tv5ak0bsle.execute-api.eu-north-1.amazonaws.com/dev/api/v1/ai/detect":
        "684f854f-444b-4cd0-9b79-dad07bb12f57",
      "https://tv5ak0bsle.execute-api.eu-north-1.amazonaws.com/dev/api/v1/search/bynder":
        "684f854f-444b-4cd0-9b79-dad07bb12f57",
      "https://tv5ak0bsle.execute-api.eu-north-1.amazonaws.com/dev/api/v1/ai/identify":
        "684f854f-444b-4cd0-9b79-dad07bb12f57",
      "http://localhost:3000/api/idam/asset-products/":
        "684f854f-444b-4cd0-9b79-dad07bb12f57",
      "https://run-api-fmcw67neya-ew.a.run.app/api/idam/asset-products/":
        "684f854f-444b-4cd0-9b79-dad07bb12f57",
    },
  },
};
